.canvas {
  .loading-overlay {
    @apply absolute text-center pt-10 z-40;
    background: rgba(#f6f6f9, 0.9); // body bg color
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  background: #f6f6f9;
  .canvas-header {
    @apply w-full flex flex-col space-y-8 bg-white shadow px-16 pt-8;
    input {
      @apply font-semibold;
    }
  }

  .canvas-body {
    @apply pb-16 pt-1 px-16 relative;
    min-height: calc(100vh - 300px);
    background: #f6f6f9;

    .cv-container {
      @apply relative;
    }

    h1 {
      @apply my-0 text-gray-600;
      font-size: 2.2rem;
    }
    h2 {
      @apply text-gray-600;
      font-size: 1.7rem;
    }

    .cv-row {
      @apply w-full flex space-x-6 relative;
      min-height: 100px;

      // Spacing between paragraphs
      & > p:not(:first-child) {
        @apply mt-8;
      }

      .xlg {
        @apply w-full flex-grow;
      }
      .lg {
        @apply w-2/3 flex-grow;
      }
      .md {
        @apply w-1/2;
      }
      .sm {
        @apply w-1/3;
      }
      .xs {
        @apply w-1/4;
      }
    }

    .empty {
      @apply border-4 rounded-lg border-dashed transition hover:border-blue-100 cursor-pointer flex items-center justify-center;
      height: 200px;

      &::before {
        @apply text-gray-300 uppercase tracking-widest font-bold text-sm;
        content: 'Empty';
      }
    }

    .cv-header {
      @apply relative flex space-x-2 items-center;
      font-size: 1.7rem;
    }

    // Positioning of row buttons
    .cv-holder {
      @apply relative;
    }
    .cv-button {
      @apply bg-gray-600 transition bg-opacity-70 hover:bg-opacity-90 text-white rounded-full text-lg px-2 py-2 cursor-pointer inline-flex items-center justify-center;
    }
    .cv-side-button {
      @apply bg-gray-600 transition bg-opacity-70 hover:bg-opacity-90 text-white inline-flex rounded-full items-center justify-center text-lg px-2 py-2 cursor-pointer;
      width: 32px;
      height: 32px;
    }
    .cv-cell-control {
      @apply mb-2;
    }
    .cv-row-add {
      top: 0;
      left: 0;
    }
    .cv-row-controls {
      @apply absolute top-0;
      left: calc(100% + 5px);

      & > .cv-side-button {
        position: static;
        &:not(:first-child) {
          @apply mt-2;
        }
      }
    }

    .red-dot {
      @apply absolute bg-red-500 w-2 h-2 rounded-full;
      top: -3px;
      left: -3px;

      @keyframes dot-pulse {
        0% {
          opacity: 1;
          transform: scale(0.1);
        }
        70% {
          opacity: 0.2;
          transform: scale(3);
        }
        100% {
          opacity: 0;
          transform: scale(4);
        }
      }
      &.pulse::after {
        @apply absolute block bg-red-500 rounded-full;
        content: '';
        width: 6px;
        height: 6px;
        top: 1px;
        left: 1px;
        animation: dot-pulse 1s linear infinite;
      }
    }
  }

  .json-editor > div {
    height: 600px;
    textarea {
      height: 540px;
    }
  }
}
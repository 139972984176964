.pagination-container {
  padding: 10px 0;
}

.pagination-button {
  margin: 0 2px;
  cursor: pointer;

  &.active {
    background: #0072c6;
    color: #fff;
  }
}

.pagination-icon-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

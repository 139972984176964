.compliance-score  {
    .cds--data-table {
        tr {
            block-size: auto;
        }
        thead {
            block-size: 50%;
        }
        height: 100%;
    }

    .cds--data-table-container {
        height: 100%;
    }

    .cds--data-table-content {
        height: 100%;
    }
}

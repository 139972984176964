@use "@carbon/react/scss/components/data-table/skeleton";
@use "@carbon/react/scss/components/pagination";

.query-table-vis {

  .table-holder {
    @apply relative;
    &.is-loading {
      @apply pointer-events-none;
      .insight-table, .cds--data-table {
        @apply opacity-50;
      }
    }

    .loading {
      @apply absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 100;
    }
  }

  select {
    @apply shadow-none
  }
}
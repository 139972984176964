.multi-select-search-bar {
    .search-field {
        &::after {
            @apply absolute;
            content: "\e91b";

            font-family: 'ti-icons' !important;
            speak: never;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;

            /* Better Font Rendering =========== */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

            top: 50%;
            right: 6px;
            transform: translateY(-50%);
        }

        &.open::after {
            content: "\e91a";
        }
    }
}

@keyframes spinner-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.svg-spinner {
  animation: spinner-spin 0.75s linear infinite;
  transform-origin: 8px 8px;
}
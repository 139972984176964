.side-nav {
    @apply z-[90] text-gray-300 pt-14 fixed left-0 h-screen w-14 hover:w-80 bg-milliman-slate-500 duration-300 ease-in-out;
    &:hover {
        .sidebar-title, .toggle-icon {
            display:inline;
            visibility: visible;
            opacity: 1;
            transition: opacity 0.3s ease, margin-left 0.3s ease;
            font-size: 1rem;
        }

        .sidebar-title{
            margin-left: 4rem;
        }
    }

    ::-webkit-scrollbar {
        width: 20px;
        display:none;
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        @apply bg-milliman-slate-200;
        // border-radius: 20px;
        // border: 6px solid transparent;
        // background-clip: content-box;
    }

    ::-webkit-scrollbar-thumb:hover {
        @apply bg-milliman-slate-100;
    }
}

.sidebar-section{
    position:relative;
    padding-bottom: 12px;
    border-left: 3px solid transparent;
    cursor: pointer;
    &:hover {
        @apply bg-milliman-slate-400;
        .sidebar-title{
            @apply text-gray-100;
        }
    }
}

.highlight{
    @apply bg-milliman-slate-300;
        border-left:  3px solid #0078d4 !important;
        div{
            @apply text-gray-100;
        }
        .sidebar-title{
            @apply text-gray-100;
        }
}

.sidebar-icon {
    transform: scale(1.5) translateY(75%);
    margin-left: 1rem;
}

.toggle-icon {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    right: 1rem;
    margin-top: 5px;
    transition: opacity 0.3s ease, visibility 0s linear 0.3s;
  }

.sidebar-title {
    visibility: hidden;
    opacity: 0;
    font-weight: bold;
    font-size: 0.1px;
    white-space: nowrap;
    overflow: hidden; 
    text-overflow: ellipsis; 
}

.sidebar-children {
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
    max-height: 0;
    opacity: 0;
    margin-left: -3px;
    div{
        border-left: 3px solid transparent;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        padding-left: 4.5rem;
        &:hover {
            @apply bg-milliman-slate-500;
        }
    }
}

.sidebar-children.open {
    padding-top: 1rem;
    max-height: 500px;
    opacity: 1;
    transition: max-height 0.3s ease-in-out;
    overflow: auto;
    overflow-x: hidden;
    animation: hide-scroll 0.3s;
    -ms-overflow-style: none;
    scrollbar-width: none;

}

@keyframes hide-scroll {
    from, to { overflow: hidden; } 
  }

.sidebar-children.closed {
    margin-left: 4.5rem;
    max-height: 0;
    opacity: 0;
}


@keyframes spinAround {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.button {
  @apply inline-block relative rounded shadow px-3 py-1 focus:ring-4;
  transition: background-color 0.2s ease, color 0.2s ease;
  &:focus {
    outline: none;
  }
  &:disabled {
    @apply opacity-40 cursor-not-allowed;
  }
  &.small {
    @apply text-sm px-2;
  }
  &.loading {
    color: transparent !important;
    &::after {
      -webkit-animation: spinAround .5s infinite linear;
      animation: spinAround .5s infinite linear;
      border: 2px solid #dbdbdb;
      border-radius: 290486px;
      border-right-color: transparent;
      border-top-color: transparent;
      content: "";
      display: block;
      height: 1em;
      width: 1em;
      position: absolute;
      left: calc(50% - 0.5em);
      top: calc(50% - 0.5em);
    }
  }
  &.block {
    display: block;
  }

  &.primary, &.orange {
    @apply bg-orange-500 text-white hover:bg-orange-400 active:bg-orange-600 focus:ring-orange-500 focus:ring-opacity-50;
  }
  &.yellow {
    @apply bg-yellow-500 text-gray-800 hover:bg-yellow-400 active:bg-yellow-600 focus:ring-yellow-500 focus:ring-opacity-50;
  }
  &.green {
    @apply bg-green-500 text-white hover:bg-green-400 active:bg-green-600 focus:ring-green-500 focus:ring-opacity-50;
  }
  &.blue {
    @apply bg-blue-500 text-white hover:bg-blue-400 active:bg-blue-600 focus:ring-blue-500 focus:ring-opacity-50;
  }
  &.milliman-blue {
    @apply bg-milliman-blue-400 text-white hover:bg-milliman-blue-500 active:bg-milliman-blue-500 focus:bg-milliman-blue-500 focus:ring-opacity-50;
  }
  &.red {
    @apply bg-red-500 text-white hover:bg-red-400 active:bg-red-600 focus:ring-red-500 focus:ring-opacity-50;
  }
  &.light {
    @apply bg-gray-100 text-gray-800 hover:bg-gray-50 active:bg-gray-200 focus:ring-blue-500 focus:ring-opacity-50;
  }
  &.dark {
    @apply bg-gray-700 text-white hover:bg-gray-600 active:bg-gray-800 focus:ring-blue-500 focus:ring-opacity-50;
  }
  &.white {
    @apply bg-white text-gray-700 focus:ring-blue-500 focus:ring-opacity-50;
  }
  &.link {
    @apply text-medinsight-blue-500 hover:underline focus:underline;
    box-shadow: none;
    &:active {
      @apply text-blue-600;
    }
  }
  &.shade {
    @apply text-white bg-black bg-opacity-30 hover:bg-opacity-50 focus:ring-blue-500 focus:ring-opacity-50;
  }

  &.pill {
    @apply rounded-full;
  }
  &.outlined {
    @apply border;
    background: none;

    &.primary, &.orange {
      @apply border-orange-500 text-orange-500 hover:bg-orange-500 hover:text-white active:bg-orange-600;
    }
    &.yellow {
      @apply border-yellow-500 text-yellow-600 hover:bg-yellow-500 hover:text-gray-800 active:bg-yellow-600;
    }
    &.green {
      @apply border-green-500 text-green-500 hover:bg-green-500 hover:text-white active:bg-green-600;
    }
    &.blue {
      @apply border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white active:bg-blue-600;
    }
    &.red {
      @apply border-red-500 text-red-500 hover:bg-red-500 hover:text-white active:bg-red-600;
    }
    &.dark {
      @apply border-gray-700 text-gray-700 hover:bg-gray-700 hover:text-white active:bg-gray-800;
    }
  }
}

// Apply spacing between neighboring buttons
.button + .button {
  @apply ml-1;
}
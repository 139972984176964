// geographyFilter.scss
.geography-filter {
    @apply bg-white shadow-lg p-2;
    width: 18%; 
    @apply h-full; 

    h1 {
        @apply text-lg font-semibold mb-4; // Heading styles
    }

    .filter-group {
        @apply mb-4; // Margin bottom for filter groups

        label {
            @apply block text-gray-700 font-medium mb-1; // Label styles
        }

        select {
            @apply w-full p-2 border border-gray-300 rounded-md; // Select input styles
        }
    }

    button {
        @apply mt-4 bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition; // Button styles
    }
}

@use "@carbon/styles/scss/themes";
@use "@carbon/styles/scss/theme";
@use "@carbon/react/scss/components/toggle";

.toggle {
  @apply inline-block h-6 w-10 bg-gray-200 rounded-full relative cursor-pointer shadow;
  &.active {
    @apply bg-green-500;

    &.primary, &.orange {
      @apply bg-orange-500;
    }
    &.yellow {
      @apply bg-yellow-500;
    }
    &.green {
      @apply bg-green-500;
    }
    &.blue {
      @apply bg-blue-500;
    }
    &.milliman-blue {
      @apply bg-milliman-blue-500;
    }
    &.medinsight-vibrant-blue {
      @apply bg-medinsight-vibrant-blue-500;
    }
    &.red {
      @apply bg-red-500;
    }
    &.light {
      @apply bg-gray-100;
    }
    &.dark {
      @apply bg-gray-700;
    }
    &.white {
      @apply bg-white;
    }
  }

  .toggle-knob {
    @apply h-5 w-5 rounded-full bg-white absolute inline-block p-0 border-0 cursor-pointer;
    top: 2px;
    left: 2px;
    min-width: 0;
    transition: left 0.2s ease;

    &:checked {
      left: 18px;
    }
  }


  &.disabled {
    @apply opacity-50 cursor-not-allowed;

    .toggle-knob {
      @apply cursor-not-allowed;
    }
  }
}

.cds--toggle__appearance {
  display: initial;
}

.cds--toggle {
  position: relative;
}

@use "@carbon/react/scss/components/modal";
@use "@carbon/styles/scss/themes";
@use "@carbon/styles/scss/theme";

@keyframes modal-fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.2;
    }
}

.modal-overlay {
    @apply fixed top-0 left-0;
    width: 100vw;
    height: 100vh;
    z-index: 49;
    backdrop-filter: blur(3px);
}
.modal-bg {
    @apply absolute top-0 left-0 right-0 bottom-0 bg-gray-400;
    animation: modal-fade-in 0.5s ease;
    animation-fill-mode: forwards;
}
.modal {
    @apply bg-white border shadow-lg rounded px-4 pb-4 absolute;
    z-index: 105;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    overflow-y: auto;

    // &.has-buttons {
    //   padding-bottom: 5rem;
    // }
    &:has(.modal-buttons) {
        padding-bottom: 5rem;
    }

    & .modal .mb-no-absolute {
        margin: inherit;
    }
}
@screen md {
    .modal {
        top: 50%;
        left: 50%;
        right: auto;
        bottom: auto;
        transform: translate(-50%, -50%);
        width: 600px;
        min-height: 300px;
        max-height: 80vh;
        &.large {
            height: 80vh;
            width: 80vw;
        }
    }
    .big {
        width: 900px;
    }
}
@screen lg {
    .modal {
        width: 800px;
        &.large {
            height: 80vh;
            width: 80vw;
        }
    }
    .big {
        width: 1200px;
    }
}
.modal-header {
    @apply text-xl py-5 mb-2;
    position: sticky;
    left: 0;

    button {
        @apply absolute right-0 w-8 h-8 rounded-full flex items-center justify-center focus:ring-4 focus:ring-orange-500 focus:ring-opacity-50 hover:text-gray-500 transition;
        top: 50%;
        transform: translateY(-50%);
        &:focus {
            outline: none;
        }
    }
}

.scrollable-modal-header {
    @apply text-xl py-5 mb-2 pl-4;
    position: sticky;
    left: 0;

    button {
        @apply absolute right-0 w-8 h-8 rounded-full flex items-center justify-center focus:ring-4 focus:ring-orange-500 focus:ring-opacity-50 hover:text-gray-500 transition;
        top: 50%;
        transform: translateY(-50%);
        &:focus {
            outline: none;
        }
    }
}

.modal-buttons {
    @apply absolute left-0 right-0 bottom-0 px-3 py-3 bg-gray-50;
}

.mb-no-absolute {
    @apply px-3 py-3 bg-gray-50;

    .insert-modal & {
        @apply -mx-4 -mb-4;
    }
}

.modal:has(.modal) {
    overflow: hidden;
    scroll-snap-type: y mandatory;

    & > #scroll-stop {
        scroll-snap-align: start;
    }
}

html:has(.modal) {
    overflow: hidden;
}

.modal .modal-overlay {
    visibility: visible;
    width: 100%;
    height: 100%;
}

.modal.large .modal {
    top: 50% !important;
    left: 50% !important;
    visibility: visible;

    &.large {
      height: 70vh;
      width: 70vw;
    }
}

.cds--modal-close.cds--btn--primary {
    @apply bg-transparent
}

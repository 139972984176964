.progress-bar {
  @apply w-full h-full my-1 border-2 rounded-md overflow-hidden shadow;

  &.orange {
    @apply border-orange-500;
  }
  &.yellow {
    @apply border-yellow-500;
  }
  &.green {
    @apply border-green-500;
  }
  &.blue {
    @apply border-blue-500;
  }
  &.red {
    @apply border-red-500;
  }
  &.light {
    @apply border-gray-100;
  }
  &.dark {
    @apply border-gray-700;
  }

  .progress {
    @apply flex justify-start w-full h-full rounded;
    transition: 0.2s ease width;

    &.orange {
      @apply bg-orange-500;
    }
    &.yellow {
      @apply bg-yellow-500;
    }
    &.green {
      @apply bg-green-500;
    }
    &.blue {
      @apply bg-blue-500;
    }
    &.red {
      @apply bg-red-500;
    }
    &.light {
      @apply bg-gray-100;
    }
    &.dark {
      @apply bg-gray-700;
    }
  }
}
@use "@carbon/react/scss/components/tile";
@use "@carbon/styles/scss/themes";
@use "@carbon/styles/scss/theme";
.filter-bar {
  @apply bg-gray-200 border border-gray-300 rounded px-3 py-3 text-gray-500 mb-2;
}

.cds--tile {
  min-block-size: 2rem;
  padding: 0;
}

.cds--tile-content__above-the-fold {
  height: auto;
  display: flex;
  align-content: center;
  //padding: var(--cds-layout-density-padding-inline-local);
}

.cds--tile-content__below-the-fold {
  padding: var(--cds-layout-density-padding-inline-local);
  padding-top: 0;
}

.cds--tile__chevron--interactive {
  // block-size: 64px;
}

.cds--tile-content__above-the-fold:hover {
  background: var(--cds-layer-hover);
  cursor: pointer;
}

.cds--tag:first-child {
  margin-inline-start: 0;
}
.filter-steps-header {
    @apply sticky top-0 pt-1 pb-5 z-50;
    background-color: #f6f6f9;
}

.filter-steps {
    @apply relative px-8 bg-white;

    .circle-filled {
        @apply bg-blue-500 inline-block;
        height: 15px;
        width: 15px;
        border-radius: 50%;
    }
    .circle-unfilled {
        @apply border-blue-500 border inline-block;
        height: 15px;
        width: 15px;
        border-radius: 50%;
    }

    .map-selection {
        height: 400px;
    }

    .found-search {
        height: 42px;
    }


}
.nav-button {
    @apply h-full inline-block no-underline bg-milliman-slate-500 text-gray-300;
    &:hover {
        @apply bg-milliman-slate-300 no-underline
    }
}

.header {
    @apply border-gray-500 border-b transition z-[110] bg-milliman-slate-500 sticky top-0;
    height: 49px;
}

.truncate {
    white-space: nowrap;         /* Prevents the text from wrapping */
    overflow: hidden;            /* Hides the overflowing text */
    text-overflow: ellipsis;     /* Displays an ellipsis (...) to indicate truncated text */
    max-width: 80%;            /* Adjust the maximum width as needed */
}

.icon {
    transform: scale(1.2);
}

.profile-menu {
    @apply w-full bg-milliman-slate-500 mt-3 px-5 py-3 text-gray-400;
    svg {
        @apply inline;
    }
}

.header-search {
    @apply  w-full h-full border-white flex;
    input {
        @apply bg-transparent text-white border-none;
        height: 35px;
        width: calc(100% - 5px);
    }
    input:hover,
    input:focus {
        @apply text-white;
        outline: none;
        border: none;
        box-shadow: none;
    }
    input[type="search"]::-webkit-search-cancel-button {
        -webkit-appearance: none;
        appearance: none;
        margin-top: 3px;
        height: 14px;
        width: 14px;
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='white'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
        background-size: 14px 14px;
        cursor: pointer;
    }
}


.badge {
  @apply px-3 py-1 rounded mx-1 text-sm;

  &.small {
    @apply px-2 pb-0.5 pt-0 text-xs;

    .content {
      @apply pl-0;
    }
  }

  &.primary, &.orange {
    @apply bg-orange-500 text-white;
  }
  &.yellow {
    @apply bg-yellow-500 text-gray-800;
  }
  &.green {
    @apply bg-green-500 text-white;
  }
  &.blue {
    @apply bg-blue-500 text-white;
  }
  &.red {
    @apply bg-red-500 text-white;
  }
  &.light {
    @apply bg-gray-100 text-gray-800;
  }
  &.gray {
    @apply bg-gray-300 text-black
  }
  &.dark {
    @apply bg-gray-700 text-white;
  }
  &.white {
    @apply bg-white text-gray-800;
  }
  &.medinsight-orange {
    @apply bg-medinsight-orange-100 text-gray-800;
  }
  &.medinsight-vibrant-blue {
    @apply bg-medinsight-vibrant-blue-100 text-gray-800;
  }
  &.milliman-blue {
    @apply bg-milliman-blue-300 text-gray-800;
  }
  &.medinsight-spring {
    @apply bg-medinsight-spring-100 text-gray-800;
  }
  &.medinsight-yellow {
    @apply bg-medinsight-yellow-100 text-gray-800;
  }

  &.pill {
    @apply rounded-full;
  }
  &.outlined {
    @apply border;
    background: none;

    &.primary, &.orange {
      @apply border-orange-500 text-orange-500;
    }
    &.yellow {
      @apply border-yellow-500 text-yellow-600;
    }
    &.green {
      @apply border-green-500 text-green-500;
    }
    &.blue {
      @apply border-blue-500 text-blue-500;
    }
    &.red {
      @apply border-red-500 text-red-500;
    }
    &.dark {
      @apply border-gray-700 text-gray-700;
    }
  }

  button {
    @apply ml-3;
    &:focus {
      outline: none;
    }
    i {
      vertical-align: -1.5px;
    }
  }
}
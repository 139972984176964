#user-page {
    @apply bg-gray-200 pb-1 min-h-screen;

    #hero {
        @apply flex justify-center items-center text-white font-light py-10;

        h1 {
            @apply my-0 font-normal text-4xl;
        }

        input {
            @apply bg-opacity-10 border-opacity-25;
        }

        ::placeholder {
            @apply text-opacity-25 text-white;
        }
    }

    .bg-green-gradient {
        @apply bg-green-500;
        background: linear-gradient(90deg, theme('colors.green.300') 0%, theme('colors.green.500') 44%, theme('colors.green.700') 100%);
    }

    .side-menu {
        div {
            @apply p-2 py-4 border-b border-gray-200 cursor-pointer;
        }

        div.active {
            background: linear-gradient(90deg, theme('colors.gray.200') 44%, theme('colors.gray.50') 90%);
        }
    }

    nav {
        @apply text-gray-500;
    }
}

.login-page {
    @apply w-full flex justify-center;
    height: calc(100vh - 95px);

    .wrapper {
        @apply flex justify-center;
        width: 600px;
    }
}


@screen md {
  .modal.data-library-modal {
    width: 80vw;
    height: 80vh;
    background-color: #f4f4f4;
    .modal & {
      width: 70vw;
      top: 40%;
      left: 40%;
    }
  }
}
@screen lg {
  .modal.data-library-modal {
    width: 80vw;
    background-color: #f4f4f4;
    .modal & {
      width: 70vw;
      top: 40%;
      left: 40%;
    }
  }
}

#column-order {
  padding: 4px 16px 70px 32px;
  height: calc(100vh - 230px);
}

@screen md {
  #column-order {
    height: calc(80vh - 180px);
  }
}

#data-library {
  @apply relative overflow-hidden;

  #dataset-nav {
    @apply flex space-x-5 mb-5;

    .dataset {
      @apply inline-block border border-gray-400 bg-white py-1 px-4 cursor-pointer;
      &:not(:first-child) {
        @apply ml-3;
      }
      &.is-active {
        @apply border-0 bg-gray-500 text-white cursor-default;
      }
    }
  }

  #data-list {
    padding: 0px 32px 70px 16px;
    height: calc(100vh - 215px);
  }

  @screen md {
    #search-input {
      transform: translateX(-50%);
    }
    #data-list {
      height: calc(80vh - 160px);
    }
  }

  .category-label {
    @apply font-bold text-gray-400 uppercase bg-white border-b mb-3 pl-3 tracking-wider text-sm sticky top-0;
    z-index: 1;
  }

  .toggle {
    margin: 2px 0 -3px;
  }
}
@use "@carbon/react/scss/components/data-table";
@use "@carbon/react/scss/components/data-table/sort";
@use "@carbon/styles/scss/themes";
@use "@carbon/styles/scss/theme";
@use "@carbon/react/scss/components/popover";
@import "../animations";

.insight-table {
  @apply overflow-auto rounded-t border border-gray-200 bg-white relative;
  min-height: 150px;

  .sticky-col {
    @apply z-40;
    width: 230px;
    min-width: 230px;
    max-width: 230px;
    td {
      @apply p-0;
    }
    a {
      @apply text-medinsight-blue-500;
    }
    .last-sticky {
      @apply h-full w-full border-r-2 border-gray-400;
    }
  }

  table {
    @apply min-w-full;
  }
  th, td > .cell {
    @apply px-2 py-1 whitespace-nowrap overflow-hidden overflow-ellipsis hover:cursor-pointer;
    max-width: 400px;
    min-width: 180px;
  }
  thead > th {
    @apply font-bold;
  }
  th {
    @apply relative text-left cursor-pointer hover:bg-gray-300 pr-5 overflow-visible;
    color: #616771;
    &.sticky-col {
      @apply sticky;
    }

    .sort-icon {
      @apply absolute top-1 transition duration-75;
      right: 2px;
    }

    .header-name {
      @apply relative;
      font-size: 14px;
    }

    .header-menu {
      @apply absolute bg-white left-0 right-0 border border-gray-200 border-t-0 py-2 text-left z-50;
      top: calc(100% - 1px);
      animation: popup-appear 0.1s ease-in;
      display: none;

      button {
        @apply block w-full text-left text-sm p-2 hover:bg-gray-100;
        &:focus {
          outline: none;
        }
      }
    }
    &:hover .header-menu {
      display: block;
    }
  }

  tr {
    color: #39414D;
    &.dark {
      td {
        @apply bg-gray-50;
        &.color-0 {
          @apply bg-medinsight-vibrant-blue-200;
        }
        &.color-1 {
          @apply bg-medinsight-spring-200;
        }
        &.color-2 {
          @apply bg-medinsight-yellow-200;
        }
        &.color-3 {
          @apply bg-medinsight-orange-200;
        }
        &.color-4 {
          @apply bg-red-200;
        }
        &.color-5 {
          @apply bg-purple-200;
        }
      }
    }
    td {
      @apply bg-white;
      &.color-0 {
        @apply bg-medinsight-vibrant-blue-100;
      }
      &.color-1 {
        @apply bg-medinsight-spring-100;
      }
      &.color-2 {
        @apply bg-medinsight-yellow-100;
      }
      &.color-3 {
        @apply bg-medinsight-orange-100;
      }
      &.color-4 {
        @apply bg-red-100;
      }
      &.color-5 {
        @apply bg-purple-100;
      }
    }
  }
}

.dataset-legend {
  @apply text-center;

  span {
    @apply uppercase text-sm font-bold text-gray-500 block mb-2;
  }
  .legend-items {
    @apply flex justify-center;
  }

  .legend-items > div {
    @apply mx-4 inline-flex items-center;
  }

  .swatch {
    @apply bg-white rounded shadow;
    width: 20px;
    height: 20px;
  }

  .legend-items > div:nth-child(1) > .swatch {
    @apply bg-medinsight-vibrant-blue-500;
  }
  .legend-items > div:nth-child(2) > .swatch {
    @apply bg-medinsight-spring-500;
  }
  .legend-items > div:nth-child(3) > .swatch {
    @apply bg-medinsight-yellow-500;
  }
  .legend-items > div:nth-child(4) > .swatch {
    @apply bg-medinsight-orange-500;
  }
  .legend-items > div:nth-child(5) > .swatch {
    @apply bg-red-500;
  }
  .legend-items > div:nth-child(6) > .swatch {
    @apply bg-purple-500;
  }
}

.dpp-insight-table {
  @apply overflow-auto bg-background-gray-100 relative;
  min-height: 150px;

  .sticky-col {
    @apply z-40;
    width: 230px;
    min-width: 230px;
    max-width: 230px;
    td {
      @apply p-0;
    }
    a {
      @apply text-medinsight-blue-500;
    }
    .last-sticky {
      @apply h-full w-full border-r-2 border-gray-400;
    }
  }

  table {
    @apply min-w-full;
  }
  th, td {
    @apply px-3 py-2 whitespace-nowrap overflow-hidden overflow-ellipsis hover:cursor-pointer text-sm;
  }
  thead > th {
    @apply font-bold;
  }
  th {
    @apply relative text-left cursor-pointer bg-background-gray-200 hover:bg-background-gray-300 overflow-visible;

    &.sticky-col {
      @apply sticky;
    }

    .sort-icon {
      @apply absolute top-1 transition duration-75;
      right: 2px;
    }

    .header-name {
      @apply relative;
      font-size: 14px;
    }

    .header-menu {
      @apply absolute bg-white left-0 right-0 border border-gray-200 border-t-0 py-2 text-left z-50;
      top: calc(100% - 1px);
      animation: popup-appear 0.1s ease-in;
      display: none;

      button {
        @apply block w-full text-left text-sm p-2 hover:bg-gray-100;
        &:focus {
          outline: none;
        }
      }
    }
    &:hover .header-menu {
      display: block;
    }
  }

  tr {
    @apply text-background-gray-900 bg-background-gray-100 hover:bg-background-gray-200;
    &.dark {
      td {
        @apply bg-background-gray-200;
        &.color-0 {
          @apply bg-medinsight-vibrant-blue-200;
        }
        &.color-1 {
          @apply bg-medinsight-spring-200;
        }
        &.color-2 {
          @apply bg-medinsight-yellow-200;
        }
        &.color-3 {
          @apply bg-medinsight-orange-200;
        }
        &.color-4 {
          @apply bg-red-200;
        }
        &.color-5 {
          @apply bg-purple-200;
        }
      }
    }
    td {
      &.color-0 {
        @apply bg-medinsight-vibrant-blue-100;
      }
      &.color-1 {
        @apply bg-medinsight-spring-100;
      }
      &.color-2 {
        @apply bg-medinsight-yellow-100;
      }
      &.color-3 {
        @apply bg-medinsight-orange-100;
      }
      &.color-4 {
        @apply bg-red-100;
      }
      &.color-5 {
        @apply bg-purple-100;
      }
    }
  }
}

.cds--data-table {
  .sticky-col {
    @apply z-[51];
    width: 230px;
    min-width: 230px;
    max-width: 230px;
    border-collapse: separate;
    
    
    &.last-sticky {
      @apply h-full w-full border-r-2;
      border-right-color: rgb(198 198 198);
    }

    
  }

  th.sticky-col {
    @apply sticky;
  }

  tr:has(.sticky-col) {
    background-color: inherit;
  }

  td.sticky-col {
    background-color: inherit;
  }
}

#drg-page {
  #hero {
    @apply flex justify-center items-center text-white font-light py-10;

    h1 {
      @apply my-0 font-normal; 
    }
  }

  .modal {
    max-height: 95vh;
  }
}

#drg-table-view-page {
  @apply pb-8;

  & > .loading {
    @apply text-center mt-16 flex justify-center;
  }
}


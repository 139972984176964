
.alert {
  @apply rounded shadow my-2 relative flex bg-white overflow-hidden;

  &.primary-border, &.orange-border {
    @apply border border-orange-100;
  }
  &.yellow-border {
    @apply border border-yellow-200;
  }
  &.green-border {
    @apply border border-green-200;
  }
  &.blue-border {
    @apply border border-blue-100;
  }
  &.red-border {
    @apply border border-red-200;
  }

  &.dpp {
    @apply bg-medinsight-gray-500 text-white;
    &.yellow-border-dpp {
      @apply border-l-4 border-yellow-500;
    }
    &.green-border-dpp {
      @apply border-l-4 border-green-500;
    }
    &.blue-border-dpp {
      @apply border-l-4 border-blue-500;
    }
    &.red-border-dpp {
      @apply border-l-4 border-red-500;
    }
  }

  .alert-box {
    @apply flex w-16 items-center justify-center text-xl;

    &.primary, &.orange {
      @apply bg-orange-500 text-white;
    }
    &.yellow {
      @apply bg-yellow-500 text-gray-800;
    }
    &.green {
      @apply bg-green-500 text-white;
    }
    &.blue {
      @apply bg-blue-500 text-white;
    }
    &.red {
      @apply bg-red-500 text-white;
    }
  }

  .alert-box-dpp {
    @apply flex w-16 pt-5 justify-center text-xl;

    &.yellow {
      @apply text-yellow-500;
    }
    &.green {
      @apply text-green-500;
    }
    &.blue {
      @apply text-blue-500;
    }
    &.red {
      @apply text-red-500;
    }

    svg {
        @apply w-6 h-6;
    }
  }

  .alert-content {
    @apply px-5 py-4 flex-grow;
  }

  .close {
    @apply flex items-center cursor-pointer text-xl pr-4;
    opacity: 0.4;
    transition: opacity 0.2s ease;
    &:hover {
      opacity: 1;
    }
  }
}
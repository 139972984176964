.select.is-darkened {
  @apply bg-gray-50 outline-none focus:ring-0;
}

.select-menu-popup {
  @apply absolute bg-white shadow rounded px-3 py-3 border-2 border-gray-200 z-10;
  top: calc(100% + 10px);
  left: -20px;
  right: -20px;
}
.select-menu-popup::before {
  content: '';
  position: absolute;
  top: -20px;
  left: calc(50% - 10px);
  border: 10px solid transparent;
  border-bottom-color: theme('colors.gray.200');
}


@keyframes bar-move {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }

}

.loading {
  @apply inline-block;
  svg {
    @apply fill-current;
    &.primary {
      @apply text-orange-500;
    }
    &.blue {
      @apply text-milliman-blue-400;
    }
  }
  polygon {
    animation: bar-move 0.5s cubic-bezier(.85,.16,.32,.86) infinite both;
    animation-direction: alternate;
    transform-origin: 50% 65%;
  }
  .bar-2 {
    animation-delay: 0.1s;
  }
  .bar-3 {
    animation-delay: 0.2s;
  }

}
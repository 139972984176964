.radio-button-group {
    .selected {
        @apply inline-block absolute;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        margin: -6px 0px 0px -6px;
    }
    .disabled {
        @apply bg-gray-300;
    }
    .enabled {
        @apply bg-black;
    }
    .radio {
        @apply border-black border inline-block relative p-2;
        height: 15px;
        width: 15px;
        border-radius: 50%;
    }
    .disabled-border {
        @apply border-gray-300;
    }
}
#hc-index-page {

  @apply bg-gray-200 pb-1 min-h-screen;
  //margin-bottom: -1px;

  #hero {
    @apply flex justify-center items-center text-white font-light py-10;

    h1 {
      @apply my-0 font-normal;
    }

    input {
      @apply bg-opacity-10 border-opacity-25;
    }

    ::placeholder {
      @apply text-opacity-25 text-white;
    }
  }

  img {
    @apply inline-block;
    max-height: 150px;
    max-width: 200px;
  }

  .featured {
    @apply mb-6 px-4 py-4 relative;
    a {
      @apply text-lg;
    }
    .badge {
      @apply absolute;
      top: 10px;
      right: 8px;
    }
  }
  .index-link {
    @apply mb-2;
  }
  hr {
    @apply border-t-2 my-16;
  }

  tr {
    td:first-child { 
      border-top-left-radius: .25em; 
      border-bottom-left-radius: .25em; 
    }
    
    td:last-child { 
      border-top-right-radius: .25em; 
      border-bottom-right-radius: .25em; 
    }
  }

  td, th {
    @apply p-2 align-top;
  }
}

#hc-index-view-page {
  @apply pb-8;

  & > .loading {
    @apply text-center pt-16 flex justify-center;
  }
}
@font-face {
  font-family: 'ti-icons';
  src:  url('fonts/ti-icons.eot?q0iosq');
  src:  url('fonts/ti-icons.eot?q0iosq#iefix') format('embedded-opentype'),
    url('fonts/ti-icons.ttf?q0iosq') format('truetype'),
    url('fonts/ti-icons.woff?q0iosq') format('woff'),
    url('fonts/ti-icons.svg?q0iosq#ti-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ti-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add-outline:before {
  content: "\e931";
}
.icon-arrow-down:before {
  content: "\e911";
}
.icon-arrow-left:before {
  content: "\e910";
}
.icon-arrow-right:before {
  content: "\e90f";
}
.icon-arrow-up:before {
  content: "\e90e";
}
.icon-chart-bar:before {
  content: "\e91e";
}
.icon-chart-flow:before {
  content: "\e91f";
}
.icon-chart-line:before {
  content: "\e920";
}
.icon-chart-pie:before {
  content: "\e921";
}
.icon-chart-tree:before {
  content: "\e922";
}
.icon-check-circle:before {
  content: "\e90b";
}
.icon-dashboard:before {
  content: "\e923";
}
.icon-data:before {
  content: "\e924";
}
.icon-database:before {
  content: "\e925";
}
.icon-double-left:before {
  content: "\e914";
}
.icon-double-right:before {
  content: "\e913";
}
.icon-down:before {
  content: "\e91b";
}
.icon-download:before {
  content: "\e901";
}
.icon-edit:before {
  content: "\e90c";
}
.icon-error:before {
  content: "\e91c";
}
.icon-expand:before {
  content: "\e92f";
  color: #727a7d;
}
.icon-file-excel:before {
  content: "\e917";
}
.icon-file-pdf:before {
  content: "\e918";
}
.icon-file-spreadsheet:before {
  content: "\e919";
}
.icon-file-text:before {
  content: "\e929";
}
.icon-filter:before {
  content: "\e912";
}
.icon-gear:before {
  content: "\e926";
}
.icon-group:before {
  content: "\e906";
}
.icon-info:before {
  content: "\e908";
}
.icon-key:before {
  content: "\e927";
}
.icon-left:before {
  content: "\e916";
}
.icon-map:before {
  content: "\e928";
}
.icon-minus:before {
  content: "\e902";
}
.icon-notebook:before {
  content: "\e92a";
}
.icon-paint:before {
  content: "\e92b";
}
.icon-plus:before {
  content: "\e903";
}
.icon-question:before {
  content: "\e91d";
}
.icon-reset:before {
  content: "\e930";
}
.icon-right:before {
  content: "\e915";
}
.icon-save:before {
  content: "\e90d";
}
.icon-search:before {
  content: "\e905";
}
.icon-sort:before {
  content: "\e92c";
}
.icon-sort_ascend:before {
  content: "\e92d";
}
.icon-sort_descend:before {
  content: "\e92e";
}
.icon-times:before {
  content: "\e904";
}
.icon-times-circle:before {
  content: "\e90a";
}
.icon-trash:before {
  content: "\e900";
}
.icon-up:before {
  content: "\e91a";
}
.icon-user:before {
  content: "\e907";
}
.icon-warning:before {
  content: "\e909";
}
.icon-categories .path1:before {
  content: "\e933";
  color: rgb(244, 244, 244);
}
.icon-categories .path2:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(22, 22, 22);
}
.icon-categories .path3:before {
  content: "\e935";
  margin-left: -1em;
  color: rgb(22, 22, 22);
}
.icon-categories .path4:before {
  content: "\e936";
  margin-left: -1.1em;
  color: rgb(22, 22, 22);
}
.icon-categories .path5:before {
  content: "\e937";
  margin-left: -1em;
  color: rgb(22, 22, 22);
}
.icon-checkmark:before {
  content: "\ea10";
}
.icon-folder:before {
  content: "\e939";
}
.icon-table:before {
  content: "\e932";
}
.icon-unfilled-checkmark:before {
  content: "\ea11";
}

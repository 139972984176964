.centered-layout-container {
    @apply md:grid md:gap-6;

    .centered-layout {
        &.md\:span-1 {
            @apply md:col-span-1 md:col-start-2;
        }
        
        &.md\:span-2 {
            @apply md:col-span-2 md:col-start-2;
        }
        
        &.md\:span-3 {
            @apply md:col-span-3 md:col-start-2;
        }
        
        &.md\:span-4 {
            @apply md:col-span-4 md:col-start-2;
        }
        
        &.md\:span-5 {
            @apply md:col-span-5 md:col-start-2; 
        }
        
        &.md\:span-6 {
            @apply md:col-span-6 md:col-start-2;
        }
        
        &.md\:span-7 {
            @apply md:col-span-7 md:col-start-2;
        }
        
        &.md\:span-8 {
            @apply md:col-span-8 md:col-start-2;
        }
        
        &.md\:span-9 {
            @apply md:col-span-9 md:col-start-2;
        }
        
        &.md\:span-10 {
            @apply md:col-span-10 md:col-start-2;
        }
    }

    &.md\:cols-3 {
        @apply md:grid-cols-3;
    }

    &.md\:cols-4 {
        @apply md:grid-cols-4;
    }

    &.md\:cols-5 {
        @apply md:grid-cols-5;
    }

    &.md\:cols-6 {
        @apply md:grid-cols-6;
    }

    &.md\:cols-7 {
        @apply md:grid-cols-7;
    }

    &.md\:cols-8 {
        @apply md:grid-cols-8;
    }

    &.md\:cols-9 {
        @apply md:grid-cols-9;
    }

    &.md\:cols-10 {
        @apply md:grid-cols-10;
    }

    &.md\:cols-11 {
        @apply md:grid-cols-11;
    }

    &.md\:cols-12 {
        @apply md:grid-cols-12;
    }

}



@use "@carbon/react/scss/components/content-switcher";
@use "@carbon/react/scss/components/loading";

.cds--content-switcher:not(.cds--content-switcher--icon-only) .cds--content-switcher-btn {
    inline-size: auto;
}

.time-distance-access {
    font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;

    & h1, h2, h3, h4, h5, h6, p, div {
        font-family: inherit;
    }

    button.primary {
        @apply text-white mt-4 px-4 flex items-center cursor-pointer transition;
        background-color: #3b82f6; // Blue
        padding: 0.5rem 1rem;
        margin-top: 1rem;

        &:hover {
            background-color: #2563eb; // Darker blue
        }
    }
    button.primary.disabled {
        @apply bg-gray-300 text-gray-600 cursor-not-allowed;
    }
    .cds--modal-header button {
        display: none;
    }
}

.pagination:not(.new) {
  span {
    @apply h-8 w-8 mx-1 text-center;
  }

  button {
    @apply h-8 mx-1 px-1 rounded-full text-center;
    transition: background-color 0.2s ease;
    min-width: 2rem; // 2rem = w-8

    &:focus {
      outline: none;
    }
    &.active {
      @apply bg-orange-500 text-white;
    }
    &:disabled {
      @apply opacity-40 cursor-not-allowed;
    }
    &:not(:disabled):not(.active) {
      @apply hover:bg-orange-100;
    }
  }
}

.pagination.new {
  
  span.elipses {
    @apply w-12 text-center pt-2;
  }

  button {
    @apply h-12 w-12 text-center text-sm;
    > * {
      @apply border-b-4;
      border-color: transparent;
      transition: border-color 0.2s ease;
    }

    &:focus {
      outline: none;
    }
    &.active {
      > span {
        @apply border-solid border-medinsight-vibrant-blue-500 pb-2;
      }
    }
    &:disabled {
      @apply opacity-40 cursor-not-allowed;
    }
    &:not(:disabled):not(.active) {
      > span {
        @apply hover:border-medinsight-vibrant-blue-100 pb-2;
      }
    }
  }
}



#landing-page {

  #hero {
    @apply text-center flex flex-col justify-center;
    height: 400px;

    img {
      height: 150px;
    }

    input {
      @apply border-0 block text-white;
      background: rgba(0,0,0,0.2);
      width: 100%;
      max-width: 500px;
      margin: 0 auto;
    }
  }

  .landing-image-section {
    display: flex;
    justify-content: center;

    img {
      max-height: 150px;
      max-width: 150px;
    }
  }
}

.page-menu {
  @apply bg-gray-200 shadow relative border-t-4 border-blue-500;
  height: 3.5rem;

  .container {
    @apply h-full flex items-center;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply my-0 font-light;
    line-height: 3.5rem;
  }

  .center {
    @apply absolute h-full top-0;
    left: 50%;
    transform: translateX(-50%);
  }
}
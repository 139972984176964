
#api-doc-page {
  @apply pb-10;

  .nav-link {
    @apply block px-3 py-2 hover:bg-gray-200 rounded;

    &.is-active {
      @apply bg-orange-500 hover:bg-orange-400 text-white;
    }
  }

  pre {
    @apply my-5 overflow-x-auto;
  }
}
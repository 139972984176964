@use '@carbon/react/scss/components/tooltip';
$tooltip-width: 200px;

.tooltip {
  @apply relative inline-block;

  .tooltip-content {
    @apply shadow-lg bg-white rounded absolute px-3 py-3 border pointer-events-none;
    z-index: 5;
    visibility: hidden;
    width: $tooltip-width;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, -10px);

    &.wide {
      $tooltip-width: 400px !global;
      width: $tooltip-width;
    }

    &.narrow {
      $tooltip-width: 120px !global;
      width: $tooltip-width;
    }

    &.placement-start {
      left: calc(#{$tooltip-width} / 2)
    }
    &.placement-below {
      bottom: auto;
      top: 100%;
      transform: translate(-50%, 10px);
    }
  }

  &:hover .tooltip-content {
    visibility: visible;
  }
}


#data-explorer-page {
  @apply pb-8;

  & > .loading {
    @apply text-center mt-16 flex justify-center;
  }
}

.data-content {
  @apply px-10 py-8 bg-background-gray-100 h-full;
  min-height: calc(100vh - 95px);
}
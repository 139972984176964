$gray-700: #374151;
$gray-300: #D1D5DB;

.specialty-filter {
    @apply bg-white w-full;

    .flex-grow {
        overflow-y: auto;
    }

    button {
        @apply text-white mt-4 px-4 flex items-center cursor-pointer transition;
        background-color: #3b82f6; // Blue
        padding: 0.5rem 1rem;
        margin-top: 1rem;

        &:hover {
            background-color: #2563eb; // Darker blue
        }
    }
    button.disabled {
        @apply bg-gray-300 text-gray-600 cursor-not-allowed;
    }
}

.checkbox {
    @apply relative cursor-pointer border-2 flex items-center text-white;
    width: 20px;
    height: 20px;

    &.primary, &.orange {
        @apply border-orange-500;
    }
    &.yellow {
        @apply border-yellow-500;
    }
    &.green {
        @apply border-green-500;
    }
    &.blue {
        @apply border-blue-500;
    }
    &.milliman-blue {
        @apply border-milliman-blue-500;
    }
    &.medinsight-vibrant-blue {
        @apply border-medinsight-vibrant-blue-500;
    }
    &.red {
        @apply border-red-500;
    }
    &.light {
        @apply border-gray-100;
    }
    &.dark {
        @apply border-gray-700;
    }
    &.white {
        @apply border-white;
    }


    &.active {
        @apply bg-green-500;

        &.primary, &.orange {
            @apply bg-orange-500;
        }
        &.yellow {
            @apply bg-yellow-500;
        }
        &.green {
            @apply bg-green-500;
        }
        &.blue {
            @apply bg-blue-500;
        }
        &.milliman-blue {
            @apply bg-milliman-blue-500;
        }
        &.medinsight-vibrant-blue {
            @apply bg-medinsight-vibrant-blue-500;
        }
        &.red {
            @apply bg-red-500;
        }
        &.light {
            @apply bg-gray-100;
        }
        &.dark {
            @apply bg-gray-700;
        }
        &.white {
            @apply bg-white;
        }
    }

    &.disabled {
        @apply opacity-50 cursor-not-allowed;
    }
}
@import "../animations";

.popup {
  @apply absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 z-[101];
  animation: popup-appear 0.1s ease-in;
  overflow-y: auto;

  .menu-item {
    @apply block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left;
    min-height: 36px;
    &.is-active {
      @apply bg-blue-100;
    }
    &.is-focus {
      @apply bg-gray-200;
    }
    &.is-disabled {
      @apply text-gray-400 hover:bg-white cursor-not-allowed;
    }
  }
}
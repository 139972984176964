.landing-section{
    @apply border-b-2 border-gray-300;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    > :first-child{
        font-weight:700;
        margin-bottom: 0.75rem;
    }
}

.end{
    padding-top: 1.5rem;
    padding-bottom: 18rem;
    > :first-child{
        font-weight:700;
        margin-bottom: 0.75rem;
    }
}

.section-content{
    @apply my-4 h-48;

    p{
        @apply text-gray-500 text-sm;

    }
}


.data-item {
    @apply relative text-sm cursor-pointer py-2 mx-3 underline underline-offset-2 text-milliman-blue-400 hover:text-milliman-blue-500 break-inside-avoid;
  }
  
  .data-item::before {
    @apply absolute bg-milliman-blue-400 rounded-full;
    content: '';
    width: 5px;
    height: 5px;
    left: -10px; /* Adjust as necessary */
    top: 50%;
    transform: translateY(-50%);
  }

  .tool-icon svg {
      @apply w-6 h-6;
  }
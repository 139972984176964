.drg-select {
    .mdc {
        .badge {
            @apply text-lg font-bold;
        }
    }

    .select {
        min-width: 100%;
    }

    #selected {
        margin-bottom: 2.5rem;
    }
}

.hcindex-browser {
  table {
    @apply w-full;
  }

  tbody tr {
    @apply my-1 odd:bg-gray-100 rounded overflow-hidden;
  }

  th, td {
    @apply px-3 py-2;

    &:first-child {
      width: 300px;
    }
  }
  td:first-child {
    border-top-left-radius: .25em;
    border-bottom-left-radius: .25em;
  }

  td:last-child {
    border-top-right-radius: .25em;
    border-bottom-right-radius: .25em;
  }
}
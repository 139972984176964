.grid-layout {
  @apply w-2/3 grid grid-cols-3 gap-6;
}

@media only screen and (max-width: 700px) {
  .grid-layout {
    @apply grid-cols-1;
  }
}

@media only screen
  and (min-width:700px)
  and (max-width: 1090px) {
  .grid-layout {
    @apply grid-cols-2;
  }
}

@media only screen and (min-width: 3500px) {
  .grid-layout {
    @apply grid-cols-4;
  }
}

.badge-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.search-page {
  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    appearance: none;
    margin-top: 3px;
    height: 14px;
    width: 14px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='222B36'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
    background-size: 14px 14px;
    cursor: pointer;
  }
  .search-type {
    appearance: auto;
  }
}
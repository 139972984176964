@use '@carbon/react/scss/type';
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./icons/style.css";

.container {
    @apply max-w-7xl mx-auto px-2 sm:px-6 lg:px-8;
}

.dpp-container {
    min-height: calc(100vh - 95px);
}

body {
    @apply text-black font-nunito;
    min-height: 100vh;
    background: #f6f6f9;
}

@layer base {
    h1 {
        @apply text-2xl mt-5 mb-2 font-semibold font-sans;
    }
    h2 {
        @apply text-xl mt-5 mb-2 font-semibold font-sans;
    }
    h3 {
        @apply text-lg mt-5 mb-2 font-semibold font-sans;
    }
    h4 {
        @apply mt-5 mb-2 font-semibold font-sans;
    }
    a {
        @apply text-medinsight-blue-500 hover:underline;
    }
    code {
        @apply text-sm bg-gray-100 rounded px-2 py-1 font-mono text-blue-500;
    }
    p {
        &:not(:first-child) {
            @apply mt-3;
        }
        &:not(:last-child) {
            @apply mb-3;
        }
    }

    hr {
        @apply my-5;
    }

    // Inputs
    input:not(.cds--text-input), select:not(.cds--select-input) {
        @apply appearance-none bg-white rounded shadow border px-3 py-2 max-w-full focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50;
        min-width: 300px;
        &:focus {
            outline: none;
        }

        &.invalid {
            @apply ring ring-orange-500 ring-opacity-50;
        }
        &:disabled {
            @apply bg-gray-100 cursor-not-allowed;
        }
    }

    textarea {
        @apply appearance-none bg-white rounded shadow border px-3 py-2 w-full focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50;
        min-height: 200px;
    }

    .bg-blue-gradient {
        @apply bg-blue-500;
        background: linear-gradient(90deg, theme('colors.blue.300') 0%, theme('colors.blue.500') 44%, theme('colors.blue.700') 100%);
    }

    pre {
        @apply bg-gray-100 px-3 py-3 rounded text-blue-500 text-sm font-mono;
    }

    dt {
        @apply text-blue-500 font-bold mt-5 mb-1;
    }
}
